import React, { useContext, useEffect } from 'react'
import { getOrders, orderDetails } from '../../api/api'
import { Card, ListGroup, Modal, Spinner, Table } from 'react-bootstrap'
import { useState } from 'react'
import Layout from '../layout/layout'
import { AuthContext } from '../../context/context'

const Dashboard = () => {

    const [orderList, setOrderList] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const { userId } = useContext(AuthContext);


    const handleClose = () => {
        setShow(false)
        setOrderDetail([]);
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (userId)
            orders(userId);
    }, [userId])


    const orders = async (value) => {
        setLoading(true);
        setOrderList([]);
        try {
            const response = await getOrders(value);
            setOrderList(response.orders)

        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false);

        }
    }

    // const orderDetailList = async (id) => {
    //     const response = await orderDetails(id);
    //     console.log('order detail', response);
    //     setOrderDetail(response.orders);
    // }


    const handleSaleId = async (id) => {
        setModalLoading(true);
        handleShow();
        const response = await orderDetails(id);
        setOrderDetail(response?.orders);
        setModalLoading(false);
    }

    return (
        <Layout>


            <div className="container mt-4">
                <Card>
                    <Card.Body>
                        <div className="row">
                            <div className="d-flex align-items-center">
                                <h2>Invoices</h2>
                                {isLoading && <Spinner animation="border" role="status" className="ml-2">

                                </Spinner>}
                            </div>
                            {!isLoading &&
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Total</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderList?.map(order =>
                                            <tr>
                                                <td>{order?.invoice_number}</td>
                                                <td>{order?.date}</td>
                                                <td>{order?.total}</td>
                                                <td>{order?.status}</td>
                                                <td><button className="btn btn-primary" onClick={() => handleSaleId(order?.invoice_id)}>View Items</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            }
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Line Items

                        {modalLoading && <Spinner animation="border" role="status" className="ml-2" size="sm">

                        </Spinner>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!modalLoading &&
                        <Table striped bordered hover>
                            <thead>
                                <tr>

                                    <th>Name</th>
                                    <th>Sku</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderDetail?.line_items?.map(order =>
                                    <tr>
                                        <td>{order?.name}</td>
                                        <td>{order?.sku}</td>
                                        <td>{order?.quantity}</td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </Modal.Body>

            </Modal>
        </Layout>


    )
}

export default Dashboard