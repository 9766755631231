import React, { useContext, useState } from 'react'
import logoImage from '../../assets/logo.jpeg';
import './layout.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import { BASE_URL, DASHBOARD_URL, FORM_URL, PROFILE_URL } from '../../routes/routes';

const NavHeader = ({ children }) => {


    const { userName, setIsAuthenticated, isAuthenticated } = useContext(AuthContext);

    const navigate = useNavigate();


    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        navigate(BASE_URL);
    }

    const toggleSidebar = () => {
        const body = document.body;
        body.classList.toggle('toggle-sidebar');
    };




    return (
        <>
            {/* <header className='nav-header'>
                <img src={logoImage} alt="Logo" style={{ height: '3rem' }} />
                <div>
                    {isAuthenticated && <>
                        {location.pathname === DASHBOARD_URL ? (
                            <Link to={FORM_URL}>Create Order</Link>
                        ) : (
                            <Link to={DASHBOARD_URL}>Dashboard</Link>
                        )}
                    </>}
                    {"  "}
                    {isAuthenticated && (<>
                        <span>{userName}</span>
                        <button className='btnLogout mx-4' onClick={handleLogout}>Logout</button>
                    </>
                    )}
                </div>
            </header> */}
            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        <img src={logoImage} alt="Logo" style={{ height: '3rem' }} />
                    </a>
                    {isAuthenticated && <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>}
                </div>
                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item px-3 tw-border-2 tw-border-solid tw-border-green-500 tw-rounded">

                        </li>

                        {isAuthenticated &&
                            <li className="nav-item dropdown pe-3">
                                <a className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                                    <img src="https://via.placeholder.com/50" alt="Profile" className="rounded-circle" />
                                    <span className="d-none d-md-block dropdown-toggle ps-2">{userName}</span>
                                </a>

                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                    <li className="dropdown-header">
                                        <h6>{userName}</h6>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <Link to={PROFILE_URL} className="dropdown-item d-flex align-items-center">
                                            <i className="bi bi-person"></i>
                                            <span>Profile</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <button onClick={handleLogout} className="dropdown-item d-flex align-items-center">
                                            <i className="bi bi-box-arrow-right"></i>
                                            <span >Sign Out</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>}
                    </ul>
                </nav>
            </header>

        </>
    )
}

export default NavHeader