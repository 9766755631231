import React, { useContext } from 'react'
import Layout from '../layout/layout'
import { Button, Card } from 'react-bootstrap'
import { AuthContext } from '../../context/context';
import defaultImage from '../../assets/images/default-img.png';
import ResetPasswordPage from '../Auth/Reset';

const Profile = () => {

    const { userName } = useContext(AuthContext);

    return (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <Card style={{ width: '14rem' }}>
                            <Card.Img variant="top" src={defaultImage} />
                            <Card.Body>
                                <Card.Title>{userName}</Card.Title>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-8">
                        <Card>
                            <Card.Body>
                                <ResetPasswordPage />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Profile