import React, { useState } from 'react'
import NavHeader from './navbar'
import Sidebar from './sidebar'

const Layout = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') !== null);
    return (
        <>
            <NavHeader />
            {isAuthenticated && <Sidebar />}
            <main className="main" id={isAuthenticated ? 'main' : ''}>
                {children}
            </main>
        </>
    )
}

export default Layout