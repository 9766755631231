import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import ResetPasswordPage from './components/Auth/Reset.jsx';
import CustomForm from './components/orders/createOrder/form';
import Dashboard from './components/dashboard/dashboard';
import Login from './components/Auth/Login.jsx';
import { useContext, useState } from 'react';
import { BASE_URL, DASHBOARD_URL, FORM_URL, PROFILE_URL, RESET_URL } from './routes/routes';
import Profile from './components/profile/profile';
import { AuthContext } from './context/context';



function App() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        <Route path={BASE_URL} element={<Login />} />
        <Route path={RESET_URL} element={<ResetPasswordPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path={DASHBOARD_URL} element={<Dashboard />} />
          <Route path={FORM_URL} element={<CustomForm />} />
          <Route path={PROFILE_URL} element={<Profile />} />
        </Route>
      </Routes>
    </Router>
  );
}

const ProtectedRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);


  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={BASE_URL} />
  );

};

export default App;




