import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') !== null);
    const [userName, setUserName] = useState();
    const [userEmail, setUserEmail] = useState();
    const [userId, setUserId] = useState();
    const [companyName, setCompanyName] = useState();
    const [taxType, setTaxType] = useState();

    const localWork = localStorage.getItem('token');

    useEffect(() => {

        const userData = JSON.parse(localStorage.getItem('token'));

        if (userData) {
            setIsAuthenticated(true);
            setUserName(userData.userName);
            setUserEmail(userData.email);
            setUserId(userData.id);
            setCompanyName(userData.companyName)
            setTaxType(userData.taxType)
        }


    }, [localWork])



    return (
        <AuthContext.Provider value={{
            isAuthenticated, setIsAuthenticated, userName,
            setUserName, userEmail, setUserEmail,
            userId, setUserId, companyName, taxType
        }}>
            {children}
        </AuthContext.Provider>
    );
};