import React, { useContext, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './auth.css';
import { login } from '../../api/api';
import { AuthContext } from '../../context/context';
import { DASHBOARD_URL, RESET_URL, } from '../../routes/routes';
import Layout from '../layout/layout';

const Login = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState();

    const { isAuthenticated, setIsAuthenticated, setUserId, setCompanyName, setTaxType } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleAuth = async () => {
        setMessage("");
        setLoading(true);

        if (!email || !password) {
            setMessage("Please enter both email and password.");
            setLoading(false);
            return;
        }


        const response = await login(email, password);
        console.log('login', response)
        if (!response?.user_found) {
            setMessage(response.message);
        } else {
            const userData = {
                "token": response?.auth_token,
                "userName": response?.user_found?.customer_name,
                "email": response?.user_found?.email,
                "id": response?.user_found?.contact_id,
                "companyName": response?.user_found?.company_name,
                "taxType": response?.user_found?.cf_tax_type
            }
            localStorage.setItem("token", JSON.stringify(userData));
            // setUserName(response?.user_found);
            // setUserEmail(email);
            setIsAuthenticated(true);
            setUserId(response?.user_found?.contact_id);
            setCompanyName(response?.user_found?.company_name)
            setTaxType(response?.user_found?.tax_type);
            navigate(DASHBOARD_URL);
        }
        setLoading(false);
        clear();
    }

    const clear = () => {
        setEmail("");
        setPassword("");

    }

    if (isAuthenticated) {

        return <Navigate to={DASHBOARD_URL} />
    }

    return (
        <Layout>
            <Container className="authContainer">
                <Row className="authRow">
                    <Col>
                        <h1 style={{ marginBottom: '2rem', textAlign: 'center' }}>Login</h1>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>

                            <Button variant="primary" type="button" style={{ width: '100%', marginTop: '1rem' }} onClick={handleAuth}>

                                Login
                                {isLoading && <Spinner animation="border" role="status" size="sm" className="ml-2">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>}

                            </Button>

                            {message && <Alert variant={'danger'} className="mt-4">{message}</Alert>}
                            <br />
                            <Link className="mt-2" to={RESET_URL} disabled={isLoading}>Reset your password now</Link>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Login