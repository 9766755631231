import React, { useCallback, useEffect, useState } from "react";
import { Form, Button, Card, Table, Spinner } from "react-bootstrap";
import { createOrder, getItems } from "../../../api/api";
import './form.css';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import { useContext } from "react";
import { AuthContext } from "../../../context/context";
import Layout from "../../layout/layout";




const CustomForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    // const [companyName, setCompanyName] = useState("");
    const [dropValue, setDropValue] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [dropDownList, setDrownList] = useState([]);
    const [nameError, setNameError] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [dropError, setDropError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [formLoad, setFormLoad] = useState(true);
    const [quantityError, setQuantityError] = useState("");
    const [orderList, setOrderList] = useState([]);
    const quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const { userEmail, companyName, taxType } = useContext(AuthContext);


    const handleSubmit = (event) => {
        event.preventDefault();
        let errors = [];

        // if (name.trim() === "") {
        //     setNameError("Please enter your name");
        // } else {
        //     setNameError("");
        // }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Please enter a valid email");
        } else {
            setEmailError("");
        }
        // if (companyName.trim() === "") {
        //     setCompanyNameError("Please enter the company name");
        // } else {
        //     setCompanyNameError("");
        // }
        // if (selectedItems.length === 0) {
        //     setDropError("Please select an item");
        // } else {
        //     setDropError("");
        // }


        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i].quantity === "" || selectedItems[i].quantity === 0) { // If no option is selected
                errors[i] = 'Please select quantity'; // Set error message for that Combobox
            } else {
                errors[i] = ''; // Clear error message for that Combobox
            }
        }

        setQuantityError(errors);

        if (userEmail && selectedItems.length > 0 && errors.filter((error) => error !== '').length === 0) {
            postData();
        }
    };

    const postData = async () => {
        setIsLoading(true);
        setSuccessMessage("");
        let orderData = {
            // 'name': name,
            'email': userEmail,
            // 'companyName': companyName,
            'selectedItems': selectedItems
        }

        const data = await createOrder(orderData);

        if (data.success)
            setSuccessMessage("Order has been submitted successfully");

        clearData();

        if (data)
            setIsLoading(false);

    }

    const clearData = () => {
        // setName("");
        setEmail("");
        // setCompanyName("")
        setSelectedItems([]);
    }



    useEffect(() => {
        fetchData();
    }, [taxType])


    const fetchData = async () => {

        setFormLoad(true);
        try {
            const { items } = await getItems();

            const list = items?.filter((item, index) => item.sku.slice(0, 2) === taxType)

            if (list.length > 0)
                setDrownList(list)

        } catch (error) {

        } finally {
            setFormLoad(false);
        }

    }

    const handleItemClick = (value) => {
        const item = JSON.parse(value);

        // Check if the item is already in the selected items list
        const itemIndex = selectedItems.findIndex(
            (selectedItem) => selectedItem.id === item.item_id
        );

        if (itemIndex !== -1) {

            // If the item is already in the selected items list, increase the quantity
            setSelectedItems((prevSelectedItems) =>
                prevSelectedItems.map((selectedItem, index) =>
                    index === itemIndex
                        ? {
                            ...selectedItem,
                            // quantity: selectedItem.quantity + 1,
                            //quantity: (selectedItem.quantity + 1) * item.cf_moq_kg
                        }
                        : selectedItem
                )
            );
        } else {

            // If the item is not in the selected items list, add it with a quantity of 1
            setSelectedItems((prevSelectedItems) => {

                return (
                    [
                        ...prevSelectedItems,
                        {
                            id: item.item_id,
                            name: item.name,
                            rate: item.rate,
                            // quantity: 0,
                            quantity: 0,
                            unitValue: item.cf_moq_kg
                        },
                    ])
            });
        }
    }


    const handleQuantityChange = useCallback((index, q) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.map((selectedItem, itemIndex) => {
                if (index === itemIndex) {
                    // if (quantity <= 0) {
                    //     quantity = 1;
                    // }

                    return { ...selectedItem, quantity: q * selectedItem.unitValue };
                }
                return selectedItem;
            })
        );
    }, [])

    const handleRemoveItem = (index) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.filter((_, itemIndex) => itemIndex !== index)
        );
    }

    


    return (

        <Layout>
            {/* <div className="formContainer"> */}
            <Card>
                <Card.Body>
                    <h3>
                        Ingredient Ordering Form for {companyName}
                    </h3>
                    <p>
                        Please place your ingredient orders here.
                        The email you provide will be the email where you receive confirmation and updates on your orders.
                    </p>
                    {(formLoad || dropDownList?.length < 1) ? <Spinner animation="border" role="status" size="sm" className="ml-2">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : (
                        <>
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="d-flex align-items-center">
                                        <h2>Ingrdients Available for Ordering</h2>
                                        {/* {formLoad && <Spinner animation="border" role="status" className="ml-2">

                                    </Spinner>} */}
                                    </div>

                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>

                                                <th>Name</th>
                                                <th>Unit</th>
                                                <th>MOQ/KGs</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dropDownList?.map(order =>
                                                <tr>
                                                    <td>{order?.name}</td>
                                                    <td>1</td>
                                                    <td>{order?.cf_moq_kg}</td>


                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                </div>

                            </div>
                            <Form onSubmit={handleSubmit}>

                                <div className="col-12 mt-4">
                                    <Form.Group controlId="formBasicSelect">

                                        <Form.Control
                                            as="select"
                                            value={dropValue}
                                            onChange={(e) => handleItemClick(e.target.value)}
                                        >
                                            <option value="">Click here to select your ingredients*</option>
                                            {dropDownList?.map((item) => (
                                                <option key={item.item_id} value={JSON.stringify(item)}>{item.name}</option>
                                            ))}


                                        </Form.Control>
                                        <Form.Text className="text-danger">{dropError}</Form.Text>
                                    </Form.Group>
                                </div>


                                <Table className="mt-4" striped hover responsive>
                                    {selectedItems.length > 0 &&
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>KGs to Order</th>

                                                {/* <th>Rate</th>
                                        <th>Amount</th> */}

                                            </tr>
                                        </thead>
                                    }
                                    <tbody>
                                        {selectedItems?.map((item, index) => {

                                            return (
                                                <tr>
                                                    <td> {item.name}</td>
                                                    <td className="d-flex">
                                                        {/* <button
                                            className="count-button"
                                            type="button"
                                            onClick={() =>
                                                handleQuantityChange(index, item.quantity - 1)
                                            }
                                        >
                                            -
                                        </button> */}
                                                        {" "}
                                                        {/* <Form.Control
                                                className="quantity-input"
                                                type="number"
                                                min="1"
                                                value={item.quantity}
                                                onBlur={(e) => {
                                                    let value = parseInt(e.target.value);
                                                    console.log('--', e.target.value === "")
                                                    if (value === 0 || e.target.value === "") {
                                                        handleQuantityChange(index, 1);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let value = parseInt(e.target.value);
                                                    // if (isNaN(value) || value < 1) {
                                                    //     value = 1;
                                                    // }
                                                    handleQuantityChange(index, value);
                                                }}
                                                style={{
                                                    // width: `${(item.quantity.toString().length + 1) * 35}px`,
                                                    width: 'auto',
                                                    marginLeft: '15px',
                                                    marginRight: '15px',
                                                    textAlign: 'center',
                                                }}
                                            /> */}
                                                        <div>
                                                            <Form.Group controlId="formBasicQuantity">

                                                                <Combobox
                                                                    placeholder="Select quantity in units"
                                                                    data={quantityOptions}
                                                                    onChange={(e) => handleQuantityChange(index, e)}
                                                                />
                                                                <Form.Text className="text-danger">{quantityError[index]}</Form.Text>
                                                            </Form.Group>
                                                        </div>

                                                        {" "}
                                                        {/* <button
                                                className="count-button"
                                                type="button"
                                                onClick={() =>
                                                    handleQuantityChange(index, item.quantity + 1)
                                                }
                                            >
                                                +
                                            </button> */}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {item.quantity}
                                                        </div>
                                                    </td>
                                                    {/* <td>{item.rate}</td> */}
                                                    {/* <td>
                                            {(item.rate * item.quantity).toFixed(2)}</td> */}
                                                    <td>
                                                        <button

                                                            className="cross-button"
                                                            type="button"
                                                            onClick={() => handleRemoveItem(index)}
                                                        >X</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>

                                <div>
                                    <Button variant="success" type="submit">
                                        Submit
                                        {isLoading && <Spinner animation="border" role="status" size="sm" className="ml-2">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>}
                                    </Button>
                                    {successMessage && <Form.Text className="text-success mx-2">{successMessage}</Form.Text>}
                                </div>
                            </Form>
                        </>
                    )}
                </Card.Body>
            </Card>
            {/* </div> */}
        </Layout >

    );
};

export default CustomForm;