import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './auth.css';
import { BASE_URL } from '../../routes/routes';
import { resetPassword } from '../../api/api';
import Layout from '../layout/layout';
import { AuthContext } from '../../context/context';

const ResetPasswordPage = () => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setLoading] = useState(false);


    const { userEmail, isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        setEmail(userEmail);
    }, [userEmail])

    const handleReset = async () => {
        setErrorMessage("");
        setSuccessMessage("");
        setLoading(true);
        try {

            if (!email || !password || !confirmPassword) {
                setErrorMessage('All fields are required');

            } else if (password !== confirmPassword) {
                setErrorMessage('Passwords do not match');
                return;
            }
            else {
                const response = await resetPassword(email, password);
                if (!response.user_found) {
                    setErrorMessage(response.message);
                    return;
                } else {
                    setSuccessMessage("Password has been successfully reset");
                    clear();
                    if (isAuthenticated) {
                        localStorage.clear("token");
                        setIsAuthenticated(false);
                        navigate(BASE_URL);
                    }
                }

            }


        } catch (err) {
            console.log('err', err)
        } finally {
            setLoading(false);

        }
    }


    const clear = () => {
        setPassword("");
        setConfirmPassword("");
        setEmail("");
    }

    const authReset = (
        <Col>
            <h1 style={{ marginBottom: '2rem', textAlign: 'center' }}>Reset Password</h1>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    {!isAuthenticated ? (
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    ) : (
                        <Form.Control type="email" disabled={true} value={userEmail} />
                    )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </Form.Group>

                <Button variant="primary" type="button" onClick={handleReset} style={{ width: '100%', marginTop: '1rem' }}>
                    Reset Password
                    {isLoading && <Spinner animation="border" role="status" size="sm" className="ml-2">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                </Button>

                {errorMessage && <Alert variant='danger' className="mt-4">{errorMessage}</Alert>}
                {successMessage && <Alert variant='success' className="mt-4">{successMessage}</Alert>}
                <br />
                {!isAuthenticated &&
                    <Link to={BASE_URL}>Go back to login page</Link>}
            </Form>
        </Col>
    )

    return (
        <>
            {isAuthenticated ? (authReset) : (<Layout>
                <Container className="authContainer">
                    <Row className="authRow">{authReset}
                    </Row>
                </Container>
            </Layout>)}

        </>
    );
};

export default ResetPasswordPage;