import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from "classnames";
import { DASHBOARD_URL, FORM_URL } from '../../routes/routes';

const Sidebar = () => {

    const location = useLocation();

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item">
                    <Link className={classNames(
                        "nav-link", location.pathname === DASHBOARD_URL ? 'active' : 'collapsed'
                    )} to={DASHBOARD_URL}>
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={classNames(
                        "nav-link", location.pathname === FORM_URL ? 'active' : 'collapsed'
                    )} to={FORM_URL}>
                        <i className="bi bi-journal-text"></i>
                        <span>Create Order</span>
                    </Link>
                </li>
            </ul>
        </aside>
    )
}

export default Sidebar