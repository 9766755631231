import $ from 'jquery';

//const URL = 'https://plantagusto.com/planta_dev_API/api';
const URL = 'https://portal.plantagusto.com/api';
const AUTH_TOKEN = 'MzQ0MDAzMzAwMDAwNDI4NjEzNzp1cGRhdGVIaWtlSW52XzE=';

export const getItems = async () => {
    try {
        const response = await fetch(`${URL}/get_items.php`);
        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
    }
};


export const createOrder = async (orderData) => {
    try {
        const response = await fetch(`${URL}/create_order.php`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(orderData),
        });
        const data = await response.json();
        console.log('data2', data);
        return data;
    } catch (error) {
        console.error(error);
    }
};


export const getOrders = async (id) => {
    try {
        const response = await fetch(`${URL}/db_orders.php`, {
            method: 'POST',
            headers: {
                'Authorization': AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                id: id
            }),
        });
        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
    }
};


export const orderDetails = async (id) => {
    try {
        const response = await fetch(`${URL}/get_order.php`, {
            method: 'POST',
            headers: {
                'Authorization': AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                "id": id
            }),
        });
        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
    }
};



export const login = async (email, password) => {
    try {
        const response = await fetch(`${URL}/login.php`, {
            method: 'POST',
            headers: {
                'Authorization': AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                email: email,
                pass: password,
            }),
        });

        const data = await response.json();
        console.log('data', { ...data, 'auth_token': AUTH_TOKEN });
        return { ...data, 'auth_token': AUTH_TOKEN };
    } catch (error) {
        console.error(error);
    }
};



export const resetPassword = async (email, password) => {
    try {
        const response = await fetch(`${URL}/reset.php`, {
            method: 'POST',
            headers: {
                'Authorization': AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                email: email,
                pass: password,
            }),
        });

        const data = await response.json();
        console.log('data', data);
        return data;
    } catch (error) {
        console.error(error);
    }
};


// export const login = async (email, password) => {
//     try {
//         const response = await $.ajax({
//             url: `${URL}/login.php`,
//             method: 'POST',
//             headers: {
//                 'Authorization': AUTH_TOKEN,
//                 'Content-Type': 'application/json',
//             },
//             dataType: 'json',
//             data: JSON.stringify({
//                 email: email,
//                 pass: password,
//             }),
//         });

//         console.log('data', { ...response, 'auth_token': AUTH_TOKEN });
//         return response;
//     } catch (error) {
//         console.error(error);
//     }
// };  